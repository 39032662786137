.App {
  display: flex;
  font-family: "IBM Plex Sans", sans-serif;
}

.sidebar {
  width: 180px;
  border-right: 1px solid #ccc;
  background: #2CB673;
  height: 100vh;
  position: fixed;
}

.logo {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.logo img {
  margin: 20px auto;
  width: 90px;
}

.content {
  flex: 1;
  padding: 20px;
  margin-left: 180px;
}

.service-list button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
  font-size: 12px;  /* Smaller font size */
  color: #fff;
  text-indent: 20px;
}

.service-list button:hover {
  background-color: #16C286;
}

.service-details h2 {
  margin-top: 0;
}

.service-details table {
  width: 100%;
  border-collapse: collapse;
}

.service-details th, .service-details td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
  font-size: 12px;  /* Smaller font size */
}

.service-details button {
  padding: 6px 12px;
  background-color: #2cb673;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;  /* Smaller font size */
}

.service-details button:hover {
  background-color: #117c49;
  color: #FFF;
}

.release-badge {
  display: inline-block;
  padding: 4px 8px;
  margin: 5px;
  border: 1px solid #666;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.tag-pill {
  display: inline-block;
  background-color: #e0e0e0;
  color: #000;
  padding: 0.2em 0.6em;
  margin: 0.2em;
  border-radius: 1em;
  font-size: 0.9em;
}

.latest-pill {
  background-color: #fc074c;
  color: white;
}

.service-list .logout-button {
  position: fixed;
  bottom: 34px;
  width: 180px;
}
.service-list .manage-services-button {
  position: fixed;
  bottom: 0px;
  width: 180px;
}

.service-list a {
  text-decoration: none;
}

.mng-serv-save-btn {
  height: 24px;
  line-height: 24px;
  background-color: #2CB673;
  border: 0px;
  border-radius: 5px;
  color: #fff;
  font-size: 0.8em;
  cursor: pointer;
}

.mng-srvc-checkbox {
  padding: 0;
  float: left;
  display: inline-block;
}

.pods-info .t1rd1 {
  border-radius: 10px 0 0 0;
  border: 1px solid #2CB673;
  background: #2CB673;
  color: #fff;
}
  
.pods-info .t1rd2 {
  border-radius: 0 10px 0 0;
  border: 1px solid #2CB673;
  background: #2CB673;
  color: #fff;
}
.pods-info .t1rd {
  background: #2CB673;
  color: #fff;
  border-left: 2px solid transparent;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
}

.disFlex {
  display: flex;
}

.pods-info .image-chip {
  margin: 0.5em;
}

.deployed-pill {
  background-color: #2CB673;
  color: white;
}
.landing-arrows {
  display: inline-block;
  left: -50px;
}